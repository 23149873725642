import * as React from "react";
import styled from "styled-components";

import PageWrapper from "../../page-wrapper";
import { HeroLogos } from "./hero-logos";
import Button from "../../button";
import siteConfig from "../../../../site-config.json";
import HeroVideo from "../../../video/hero.mp4";

const HeroWrapper = styled.section`
  position: relative;
  height: 568px;
  margin-top: calc(-1 * var(--header-total-height));
  display: flex;
  text-align: center;

  @media screen and (min-width: 800px) {
    height: 100vh;
    min-height: 45em;
    max-height: 55em;
  }
`;

const TitleWrapper = styled.h2`
  margin: auto auto 0;
  font: var(--text-font-h1);
  line-height: 1.25em;
  font-weight: 700;
  text-transform: capitalize;
  text-shadow: 0 0 1px rgba(98, 52, 5, 1);

  @media screen and (min-width: 600px) {
    line-height: 98px;
    margin: auto auto 0;
  }

  .dark-mode & {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  em {
    color: var(--theme-color-primary);
    color: var(--theme-color-secondary);
    color: var(--color-brown-bramblele-100);
    font-style: normal;

    .dark-mode & {
      color: #fff2c9;
    }
  }
`;

const CenterWrapper = styled(PageWrapper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

const OverlineTextWrapper = styled.p`
  font: var(--text-font-body-large);
  font-weight: 500;
  color: var(--color-brown-bramblele-100);
  text-shadow: 0 0 1px var(--color-brown-bramblele-20);
  margin: 16px auto 32px;
  max-width: 800px;

  .dark-mode & {
    color: rgba(255, 255, 255, 0.85);
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
`;

const SubtitleTextWrapper = styled.p`
  font: var(--text-font-body-large);
  font-weight: 500;
  display: block;
  color: var(--color-brown-bramblele-100);
  position: absolute;
  bottom: 105px;
  left: 0;
  right: 0;
  text-shadow: 0 0 1px var(--color-brown-bramblele-20);

  .dark-mode & {
    text-shadow: none;
    color: rgba(255, 255, 255, 0.85);
    text-shadow: 0 -0.5px 4px rgba(98, 52, 5, 0.5);
  }

  @media screen and (min-width: 800px) {
    bottom: 105px;
  }
`;

const CtaWrapper = styled.div`
  margin-bottom: auto;

  * + * {
    margin-left: 16px;

    @media screen and (max-width: 400px) {
      margin-left: 8px;
    }
  }

  button {
    @media screen and (max-width: 400px) {
      padding: 0 10px;
    }
  }
`;

const StyledVideoWrapper = styled.div`
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-cod-gray-5);
  z-index: -1;

  .dark-mode & {
    background: var(--color-brown-bramblele-100);
  }
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  opacity: 0.4;
`;

const HeroSection = () => {
  return (
    <HeroWrapper>
      <StyledVideoWrapper>
        <StyledVideo muted autoPlay loop>
          <source src={HeroVideo} type="video/mp4" />
        </StyledVideo>
      </StyledVideoWrapper>
      <CenterWrapper>
        <TitleWrapper>
          <em>Procurement Simplified</em>
        </TitleWrapper>
        <OverlineTextWrapper>
          Simplifiy your buying with our one-stop procurement platform.
        </OverlineTextWrapper>
        <CtaWrapper>
          <Button.Primary buttonSize="large" as="a" href={siteConfig.signUpUrl}>
            Create a free account
          </Button.Primary>
          {/* <Button.Secondary
            as="a"
            buttonSize="large"
            href={siteConfig.supplierSignupUrl}
          >
            Become Supplier
          </Button.Secondary> */}
        </CtaWrapper>
        <SubtitleTextWrapper>Trusted by Industry Leaders</SubtitleTextWrapper>
        <HeroLogos />
      </CenterWrapper>
    </HeroWrapper>
  );
};

export default HeroSection;
