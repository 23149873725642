import * as React from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../components/index/hero-section";
import FeatureSection from "../components/index/feature-section";
import WorkWithUsSection from "../components/index/work-with-us-section";
import TestimonialsSection from "../components/index/testimonials-section";
import BlogSection from "../components/index/blog-section";
import siteConfig from "../../site-config.json";
import PageLayout from "../layouts/page-layout";

const IndexPage = () => {
  return (
    <PageLayout>
      <Helmet>
        <link rel="next" href={siteConfig.signUpUrl} />
        <link rel="next" href={siteConfig.supplierSignUpUpUrl} />
        <link rel="next" href={siteConfig.signInUrl} />
      </Helmet>
      <HeroSection />
      <FeatureSection />
      <TestimonialsSection />
      <BlogSection />
      <WorkWithUsSection />
    </PageLayout>
  );
};

export default IndexPage;
