import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import PageWrapper from "../../page-wrapper";
import siteConfig from "../../../../site-config.json";

const MOBILE_BREAKPOINT = "screen and (min-width: 600px)";

const LogosWrapper = styled(PageWrapper)`
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  user-select: none;
  scrollbar-width: none;
`;

const InnerWrapper = styled.div`
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${MOBILE_BREAKPOINT} {
    width: 1000px;
  }

  > * {
    flex: 0 0 auto;
    scroll-snap-align: center;
    pointer-events: none;
  }
`;

export const HeroLogos = () => {
  if (!siteConfig.features["hero-logos"]) return null;

  return (
    <LogosWrapper>
      <InnerWrapper>
        <StaticImage
          className="light-mode-only"
          src="./images/abb-logo.png"
          placeholder="none"
          alt="ABB"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/abb-logo-dark.png"
          placeholder="none"
          alt="ABB"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/nippon-paint-logo.png"
          placeholder="none"
          alt="Nippon Paint"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/nippon-paint-logo-dark.png"
          placeholder="none"
          alt="Nippon Paint"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/netsol-logo.png"
          placeholder="none"
          alt="Netsol"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/netsol-logo-dark.png"
          placeholder="none"
          alt="Netsol"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/stewart-logo.png"
          placeholder="none"
          alt="Stewart"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/stewart-logo-dark.png"
          placeholder="none"
          alt="Stewart"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/ibex-logo.png"
          placeholder="none"
          alt="ibex"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/ibex-logo-dark.png"
          placeholder="none"
          alt="ibex"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/pepsi-logo.png"
          placeholder="none"
          alt="PEPSI"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/pepsi-logo-dark.png"
          placeholder="none"
          alt="PEPSI"
          loading="eager"
          width={164}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/game-district-logo.png"
          placeholder="none"
          alt="Game District"
          loading="eager"
          width={164}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/game-district-logo-dark.png"
          placeholder="none"
          alt="Game District"
          loading="eager"
          width={164}
          layout="fixed"
        />

        {/* <StaticImage
          className="light-mode-only"
          src="./images/borjan-logo.png"
          placeholder="none"
          alt="Borjan"
          loading="eager"
          width={158}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/borjan-logo-dark.png"
          placeholder="none"
          alt="Borjan"
          loading="eager"
          width={158}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/rupali-foods-logo.png"
          placeholder="none"
          alt="Rupali Foods"
          loading="eager"
          width={158}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/rupali-foods-logo-dark.png"
          placeholder="none"
          alt="Rupali Foods"
          loading="eager"
          width={158}
          layout="fixed"
        />

        <StaticImage
          className="light-mode-only"
          src="./images/nlc-logo.png"
          placeholder="none"
          alt="National Logistics Cell"
          loading="eager"
          width={158}
          layout="fixed"
        />
        <StaticImage
          className="dark-mode-only"
          src="./images/nlc-logo-dark.png"
          placeholder="none"
          alt="National Logistics Cell"
          loading="eager"
          width={158}
          layout="fixed"
        /> */}
      </InnerWrapper>
    </LogosWrapper>
  );
};
