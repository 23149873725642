import * as React from "react";
import shuffle from "lodash/shuffle";
import styled from "styled-components";
import PageWrapper from "../../page-wrapper";
import SectionTitle from "../../section-title";
import { graphql, useStaticQuery } from "gatsby";
import siteConfig from "../../../../site-config.json";
import BlogCard, { BlogCardGrid } from "../../blog-card";

const MOBILE_BREAKPOINT = "screen and (min-width: 600px)";

const BlogSectionWrapper = styled.section`
  background: var(--color-feature-background);
  padding: 32px 0 48px;

  @media ${MOBILE_BREAKPOINT} {
    padding-top: 3.857143em;
    padding-bottom: 3.857143em;
  }

  .subtitle {
    opacity: 0.5;
    font-weight: 500;
    margin-bottom: 2.428571em;
  }
`;

const BlogSection = () => {
  const data = useStaticQuery(graphql`
    query BlogSectionPages {
      allGhostPage(
        filter: {
          featured: { eq: true }
          tags: { elemMatch: { slug: { eq: "website" } } }
        }
      ) {
        nodes {
          slug
          image: feature_image
          title
          excerpt
          publishDate: published_at(fromNow: true)
          tags {
            name
            slug
          }
        }
      }
    }
  `);

  if (!siteConfig.features.blog || !data.allGhostPage.nodes.length) return null;

  const nodes = shuffle(data.allGhostPage.nodes).slice(0, 3);

  const postSlug = (post) => {
    let filtered = post.tags.filter((item) => item.slug !== "website");

    return filtered.length > 0 ? "/" + filtered[0].slug : "";
  };

  return (
    <BlogSectionWrapper id="community">
      <PageWrapper>
        <SectionTitle>Solution Heighlights</SectionTitle>
        <p className="subtitle">
          Procure is a comprehensive procurement platform that helps businesses
          streamline their procurement process, manage spending, ensure
          compliance with tax and regulatory laws, and optimize their operations
          for maximum efficiency.
        </p>
        <BlogCardGrid>
          {nodes.map((post) => (
            <BlogCard post={post} key={post.slug} prefix={postSlug(post)} />
          ))}
        </BlogCardGrid>
      </PageWrapper>
    </BlogSectionWrapper>
  );
};

export default BlogSection;
