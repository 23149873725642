import styled from "styled-components";
import * as React from "react";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import Button from "../../button";
import BackIcon from "../../../images/chevron-left.svg";
import ForwardIcon from "../../../images/chevron-right.svg";

const StyledTestimonialWrapper = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px auto;

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1.1fr;
    grid-template-rows: auto;
    grid-gap: 24px;
  }

  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    grid-gap: 64;
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: minmax(auto, 520px) 1fr;
    grid-gap: 72px;
  }

  .image {
    width: 200px;
    height: 200px;
    margin: auto;

    @media screen and (min-width: 600px) {
      width: unset;
      height: unset;
      margin: unset;
    }

    img {
      object-fit: cover !important;
      object-position: top center !important;

      @media screen and (min-width: 600px) {
        object-fit: unset;
        object-position: unset;
      }
    }
  }

  .details {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .content {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.366;
    white-space: pre-line;

    @media screen and (min-width: 800px) {
      font-size: 1.7em;
    }

    @media screen and (min-width: 1024px) {
      font-size: 2em;
    }
  }

  .footer {
    margin-top: auto;
  }

  .name {
    font: var(--text-font-body-large);
    font-weight: 500;
    margin: 0;
  }

  .role {
    font: var(--text-font-body-small);
    font-weight: 500;
    opacity: 0.5;
    margin: 8px 0 0;
  }
`;

const TestimonialItem = ({ testimonial }) => {
  const image = getImage(testimonial.image);
  const logo = getImage(testimonial.logo);

  return (
    <StyledTestimonialWrapper>
      <GatsbyImage image={image} className="image" alt="" />
      <div className="details">
        <GatsbyImage image={logo} alt="" className="logo" />
        <p className="content">{testimonial.content}</p>
        <div className="footer">
          <p className="name">{testimonial.name}</p>
          <p className="role">{testimonial.role}</p>
        </div>
      </div>
    </StyledTestimonialWrapper>
  );
};

const StyledNavigation = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 12px;
  right: 0;
  display: flex;
  gap: 5px;

  @media screen and (min-width: 800px) {
    bottom: 48px;
  }

  button {
    background-color: var(--color-background);
  }

  .dark-mode & {
    button {
      box-shadow: none;
      background-color: var(--theme-color-secondary);
      color: rgba(255, 242, 201, 0.7);
    }

    button:hover {
      background-color: var(--theme-color-primary);
    }
  }
`;
const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      posts: allTestimonialsJson(limit: 6) {
        nodes {
          id
          image {
            childImageSharp {
              gatsbyImageData(width: 520, height: 640)
            }
          }
          logo {
            childImageSharp {
              gatsbyImageData(width: 96, height: 48, placeholder: TRACED_SVG)
            }
          }
          name
          role
          content
        }
      }
    }
  `);

  const posts = data.posts.nodes.sort(() => Math.random() - 0.5);

  const swiperRef = React.useRef();
  const prevElRef = React.useRef();
  const nextElRef = React.useRef();

  return (
    <div style={{ position: "relative" }}>
      <Swiper
        modules={[Navigation, Autoplay]}
        autoplay={{ delay: 10000 }}
        loop
        ref={swiperRef}
      >
        {posts.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <TestimonialItem testimonial={testimonial} />
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledNavigation>
        <Button.Circle
          ref={(el) => {
            prevElRef.current = el;
            const swiper = swiperRef.current.swiper;
            if (!swiper) return;
            swiper.params.navigation.prevEl = prevElRef.current;
            swiper.navigation.disabledClass = "disabled";
            swiper.navigation.init();
          }}
        >
          <BackIcon />
        </Button.Circle>
        <Button.Circle
          ref={(el) => {
            nextElRef.current = el;
            const swiper = swiperRef.current.swiper;
            if (!swiper) return;
            swiper.params.navigation.nextEl = nextElRef.current;
            swiper.navigation.disabledClass = "disabled";
            swiper.navigation.init();
          }}
        >
          <ForwardIcon />
        </Button.Circle>
      </StyledNavigation>
    </div>
  );
};

export default Testimonials;

export const TestimonialsFallback = styled.div`
  height: 640px;
`;
