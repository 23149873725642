import * as React from "react";
import styled from "styled-components";
import Loadable from "@loadable/component";
import PageWrapper from "../../page-wrapper";
import SectionTitle from "../../section-title";
import { TestimonialsFallback } from "./testimonials";

const Testimonials = Loadable(() => import("./testimonials"), {
  fallback: <TestimonialsFallback />,
});

const TestimonialsSectionWrapper = styled.section`
  padding: 48px 0;

  @media screen and (min-width: 600px) {
    padding: 96px 0;
  }
`;

const TestimonialsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  flex-wrap: wrap;

  @media screen and (min-width: 600px) {
    margin-bottom: 68px;
  }

  p {
    flex: 1 1 100%;
    opacity: 0.5;
    font-weight: 500;
    font-size: 0.8em;

    @media screen and (min-width: 600px) {
      flex: 0 1 50%;
      padding-left: 48px;
    }
  }
`;

const TestimonialsSection = () => {
  return (
    <TestimonialsSectionWrapper>
      <PageWrapper>
        <TestimonialsHeader>
          <SectionTitle>
            This is why we do.
            <br />
            What we do.
          </SectionTitle>
          <p>
            We love our customers and put them first in everything we do. We
            regularly receive incredible testimonials that they love us as much.
            Here are some picks.
          </p>
        </TestimonialsHeader>
        <Testimonials />
      </PageWrapper>
    </TestimonialsSectionWrapper>
  );
};

export default TestimonialsSection;
